import {
  CollectionTypeEnum,
  ItemTypeEnum,
  QuizTypeEnum,
} from 'constants/typesConst';
import { LanguagesEnum } from 'constants/languagesConst';
import { CollectionStatusEnum, ItemStatusEnum } from 'constants/statusConst';
import { PaymentTypeEnum } from 'constants/paymentsConst';
import { VectorsList } from 'constants/vectorsConst';
import React from 'react';
import MaterialIcons from 'assets/vectors/glyphmaps/MaterialIcons.json';
import Vector from 'components/atoms/Vector';
import { VectorIconsList } from 'components/app_common/VectorIcons';

export enum SendNotificationType {
  ToSelectedUsers = '1',
  ToTopics = '2',
}

interface IDefaultPaging {
  page: number;
  pageSize: number;
  sortField: string;
  orderType: 'asc' | 'desc';
}

export const DefaultPaging: IDefaultPaging = {
  page: 0,
  pageSize: 25,
  sortField: 'createdAt',
  orderType: 'desc',
};

// export interface ITrickDetailsPageParams {
//   mode: 'edit' | 'view' | 'add';
// }

export interface IFormData {
  title: string | null;
  description: string | null;
  type: CollectionTypeEnum | null;
  link: string | null;
  tags: string[] | null;
  public: 'false' | 'true' | null;
  items: Array<{
    id: string;
    title: string;
    tags?: string[];
    status: ItemStatusEnum;
    type: ItemTypeEnum;
    difficulty: number | null;
    public: boolean;
  }>;
  quizTypes: QuizTypeEnum[];
  namespaces: string[] | null;
  imageId: string | null;
  audioId: string | null;
  translations?: Partial<
    {
      [key in LanguagesEnum]: Partial<{
        description: string;
        title: string;
      }>;
    }
  >;
  status: CollectionStatusEnum | null;
  paymentType: PaymentTypeEnum | null;
  difficulty: number | null;
  maskAnswers: boolean | null;
  isTemplate: boolean | null;
}

export { VectorIconsList };

// export const VectorIconsList = Object.entries(MaterialIcons).map(
//   ([key, value], index) => ({
//     id: key,
//     label: key,
//     iconLeft: <Vector style={{ marginRight: '0.8rem' }} code={value} />,
//   })
// );

export const CollectionTypeTitleMap: {
  [key in CollectionTypeEnum]: string;
} = {
  Grammar: 'Grammar Course',
  Vocabulary: 'Vocabulary Course',
  PlacementTest: 'Placement Test',
  EvaluationTest: 'Evaluation Test',

  Listening: 'Listening Course',
  Speaking: 'Speaking Course',
  Reading: 'Reading Course',
  TextFragm: 'Text Fragments Course',
  VocabText: 'Vocab Text Course',
  GenericCollection: 'Generic Collection',
  TricksRoutine: 'Tricks Routine',
  Book: 'Book',

  Wizdom: 'Wizdom',
};

export const defaultValues = (type: CollectionTypeEnum | null): IFormData => ({
  title: null,
  description: null,
  type,
  link: null,
  tags: null,
  public: 'true',
  items: [],
  quizTypes: [],
  namespaces: null,
  imageId: null,
  paymentType: PaymentTypeEnum.Free,
  status: CollectionStatusEnum.OnReview,
  difficulty: null,
  audioId: null,
  maskAnswers: null,
  isTemplate: null,
});
