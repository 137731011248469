import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import {
  alpha,
  Box,
  Button,
  ButtonBase,
  colors,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import TranslatedText from 'components/atoms/TranslatedText';
import UploadedFilesAction from '../../store/actions';
import { DefaultPaging } from '../../configuration';
import {
  openDiscoverImagesModal,
  closeDiscoverImagesModal,
} from 'components/compounds/DiscoverImagesModal';
import PublicIcon from '@material-ui/icons/Public';
import { IModalParams } from '../../types';

interface IUploadFileFromURLButtonProps {
  initialSearch: IModalParams['initialSearch'];
}

const UploadFileFromURLButton = ({
  initialSearch,
}: IUploadFileFromURLButtonProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClickOpen = () => {
    openDiscoverImagesModal({
      initialSearch: initialSearch ?? '',
      type: 'pexels',
      handleSelectedFile: (payload, uploadFile) => {
        uploadFile(payload, () => {
          closeDiscoverImagesModal();

          dispatch(
            UploadedFilesAction.fetchFilesAction({
              payload: DefaultPaging,
              onSuccess: () => {},
            })
          );
        });
      },
    });
  };

  return (
    <>
      <Button
        startIcon={<PublicIcon />}
        variant="outlined"
        onClick={handleClickOpen}
      >
        <TranslatedText defaultText={'Discover More Images'} />
      </Button>
    </>
  );
};

export default UploadFileFromURLButton;
