import * as Types from '../types';
import Environment from 'config/environmentConfig';
import request from '../../../utils/request';
import * as PathUtils from 'utils/path';
import { FilePathTypes, FileType } from 'constants/filesConst';

/** Upload Single file  */
interface IUploadSingleFileOutput extends Types.ResponseOutput {
  data: {
    _id: string;
    fileName: string;
    path: string;
    fileType: string;
    attachmentType: string;
    metadata: any;
    name: string;
    size: number;
    createdAt: string;
    updatedAt: string;
  };
}

export interface IUploadSingleFilePayload {
  path: FilePathTypes;
  file: string;
  fileId?: string;

  // imageId?: string;
}

export const uploadSingleFile = async ({
  path,
  file,
  fileId,
}: IUploadSingleFilePayload): Promise<IUploadSingleFileOutput> => {
  const formData = new FormData();
  formData.append('file', file);

  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'path',
        value: path,
      },
      {
        key: 'fileId',
        value: fileId,
      },
    ],
  });

  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/file/upload-single${query}`,
    {
      body: formData,
      toastMessageVisibility: {
        error: true,
        success: true,
      },
    }
  );

  return data;
};

/** Upload Multiple files  */
export type UploadMultipleFilesData = Array<{
  fieldname: string;
  originalname: string;
  mimetype: string;
  id: string;
  dirName: string;
  fullPath: string;
}>;

interface IUploadMultipleFilesOutput extends Types.ResponseOutput {
  data: UploadMultipleFilesData;
}

export const uploadMultipleFiles = async (
  path: FilePathTypes,
  files: string[]
): Promise<IUploadMultipleFilesOutput> => {
  const formData = new FormData();
  for (const file of files) {
    formData.append('files', file);
  }

  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/file/upload-multiple?path=${path}`,
    {
      body: formData,
      toastMessageVisibility: {
        error: true,
        success: true,
      },
    }
  );

  return data;
};

/** Download File  */
export type IDownloadFileInput = {
  filepath: FilePathTypes;
  filename: string;
};

export const downloadFile = async (payload: IDownloadFileInput) => {
  const response = await request(
    'post',
    `${Environment.API_BASE_URL}/api/file/download-file`,
    {
      body: payload,
      toastMessageVisibility: {
        error: true,
        success: true,
      },
      config: {
        responseType: 'blob',
      },
    }
  );

  const { data, headers } = response;

  // console.log('Headers!!!::', response);

  const url = window.URL.createObjectURL(new Blob([data]));
  var a = document.createElement('a');
  a.href = url;
  a.download = payload.filename;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

/** Fetch Files List  */
export type IFetchFilesData = {
  items: Array<IFileDetailsData>;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
};

export interface IFetchFilesPayload {
  page?: number;
  pageSize?: number;
  orderType?: 'asc' | 'desc';
  sortField?: string;
  globalSearch?: string;
  attachmentType?: FilePathTypes;
  fileType?: FileType;
  size?: number;
}

export const fetchFilesList = async (
  payload: IFetchFilesPayload
): Promise<Types.ResponseOutputV2<IFetchFilesData>> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'pageSize',
        value: payload?.pageSize?.toString(),
      },
      {
        key: 'page',
        value: payload?.page?.toString(),
      },
      {
        key: 'orderType',
        value: payload.orderType,
      },
      {
        key: 'sortField',
        value: payload.sortField,
      },
      {
        key: 'globalSearch',
        value: payload.globalSearch,
      },
      {
        key: 'attachmentType',
        value: payload.attachmentType,
      },
      {
        key: 'fileType',
        value: payload.fileType,
      },
    ],
  });

  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/file/list${query}`
  );

  return data;
};

/** Remove File  */
export const removeFile = async (
  fileId: string
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/file/details/${fileId}`,
    {
      toastMessageVisibility: {
        error: true,
        success: true,
      },
    }
  );

  return data;
};

/** Edit File  */
export interface IEditFilePayload {
  name?: string;
}

export const editFile = async (
  fileId: string,
  payload: IEditFilePayload
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/file/details/${fileId}`,
    {
      body: payload,
      toastMessageVisibility: {
        error: true,
        success: true,
      },
    }
  );

  return data;
};

/** Get File Details  */
export interface IFileDetailsData {
  _id: string;
  fileName: string;
  path: string;
  fileType: FileType;
  attachmentType: FilePathTypes;
  metadata: any;
  createdAt: string;
  updatedAt: string;
  name?: string;
  size?: number;
}

export const getFileDetails = async (
  fileId: string
): Promise<Types.ResponseOutputV2<IFileDetailsData>> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/file/details/${fileId}`
  );

  return data;
};

/** View File */
export const openFileToNewTab = (filePath: string) =>
  window.open(`${Environment.API_BASE_URL}${filePath}`, '_blank');

export const openFileToNewTabAsync = async (fileId: string) => {
  try {
    const details = await getFileDetails(fileId);
    window.open(`${Environment.API_BASE_URL}${details.data.path}`, '_blank');
  } catch (error) {}
};

export const viewFileUrl = (filePath: string) =>
  `${Environment.API_BASE_URL}${filePath}`;

export interface IUploadSingleFileFromUrl {
  fileUrl: string;
  path: string;
}

export const uploadSingleFileByUrl = async (
  payload: IUploadSingleFileFromUrl
): Promise<IUploadSingleFileOutput> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/file/upload-by-url`,
    {
      body: payload,
      toastMessageVisibility: {
        error: true,
        success: true,
      },
    }
  );

  return data;
};
