import { Tooltip, IconButton } from '@material-ui/core';
import React from 'react';
import {
  closeImageEditorModal,
  openImageEditorModal,
} from 'components/molecules/ImageEditor';
import store from 'redux/store';
import UploadedFilesAction from '../../store/actions';
import { viewFileUrl } from 'services/api/file';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import { FilePathTypes, FileType } from 'constants/filesConst';
import { DefaultPaging } from '../../configuration';

interface IEditImageButtonProps {
  path: string;
  _id: string;
  name: string;
  fileName: string;
}

const EditImageButton = ({
  path,
  _id,
  name,
  fileName,
}: IEditImageButtonProps) => {
  return (
    <Tooltip title="Edit Image">
      <IconButton
        size="small"
        onClick={() => {
          openImageEditorModal({
            src: viewFileUrl(path ?? '') ?? '',
            onEditComplete: (file) => {
              // replace file
              // const file = event?.target?.files?.[0];

              // upload file
              store.dispatch(
                UploadedFilesAction.uploadFileAction({
                  data: {
                    path: FilePathTypes.AppFiles,
                    file: file as any,
                    fileId: _id,
                  },
                  onSuccess: () => {
                    store.dispatch(
                      UploadedFilesAction.fetchFilesAction({
                        payload: DefaultPaging,
                        onSuccess: () => {},
                      })
                    );
                  },
                })
              );
              closeImageEditorModal();
            },
            filename: name ?? fileName,
            onCloseImageEditor: () => {
              closeImageEditorModal();
            },
          });
        }}
      >
        <ImageSearchIcon />
      </IconButton>
    </Tooltip>
  );
};

export default EditImageButton;
