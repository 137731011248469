import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import TranslatedText from 'components/atoms/TranslatedText';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import {
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
} from '@material-ui/core';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import store from 'redux/store';
import FilesList from './components/FilesList';
import UploadedFilesAction from './store/actions';
import { DefaultPaging } from './configuration';
import UploadFileButton from './components/UploadFileButton';
import { IModalParams } from './types';
import EditFileDataModal from './components/EditFileDataModal';
import LoadingView from 'components/atoms/LoadingView';
import { useUploadFileStatus } from './store/selectors';
import { StateStatus } from 'redux/utils/common';
import { ImageEditorModal } from 'components/molecules/ImageEditor';
import UploadFileFromURL from './components/UploadFileFromURL';
import UploadFileFromExternalProvider from './components/UploadFileFromExternalProvider';
import useStyles from './styles';
import { FileType } from 'constants/filesConst';

export { openUploadedFilesModal, closeUploadedFilesModal } from './common';

const ModalAUploadedFiles = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_UPLOADED_FILES_LIST}
      renderContent={(props: IModalParams) => (
        <UploadedFilesContent {...props} />
      )}
      modalProps={{
        fullWidth: true,
        fullScreen: matches,
        maxWidth: 'lg',
        style: {
          minHeight: '75vh',
        },
      }}
      renderHeader={(props: IModalParams) => (
        <TranslatedText defaultText={'Files List'} />
      )}
    />
  );
};

export const UploadedFilesContent = withReducer<IModalParams>(
  ReducerKeys.UPLOADED_FILES_REDUCER
)((props: IModalParams) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(
      UploadedFilesAction.fetchFilesAction({
        payload: {
          ...DefaultPaging,
          globalSearch: props.initialSearch,
          fileType: props.type,
        },
        onSuccess: () => {},
      })
    );
  }, [dispatch, props.initialSearch, props.type]);

  return (
    <React.Fragment>
      <ImageEditorModal />
      <Loading />
      <EditFileDataModal />
      <Box className={classes.buttonsActions}>
        <UploadFileButton fileType={props.type} />
        {(props.type === FileType.Image ||
          props.type === FileType.File ||
          !props.type) && (
          <UploadFileFromExternalProvider initialSearch={props.initialSearch} />
        )}
        {/* <UploadFileFromURL /> */}
      </Box>
      <FilesList {...props} />
    </React.Fragment>
  );
});

const Loading = () => {
  const uploadStatus = useUploadFileStatus();
  return (
    <>{uploadStatus === StateStatus.Pending && <LoadingView fullScreen />}</>
  );
};

export default ModalAUploadedFiles;
