import { removeDuplicates } from 'utils/arrays';
import { AppTypes } from './namespaceConst';

// Learning Modules
// Entries
// Entry Units
// Learning Units

export enum ItemTypeEnum {
  GenericItem = 'GenericItem',

  // Vocabulary, Speaking
  Word = 'Word',
  Phrase = 'Phrase',
  // Subtitle = 'Subtitle',
  Quote = 'Quote',
  Idiom = 'Idiom',
  Saying = 'Saying',
  Sentence = 'Sentence',
  Text = 'Text',

  // Grammar, PlacementTest, Listening, Reading
  MultipleChoice = 'MultipleChoice',
  QuestionAnswer = 'QuestionAnswer',
  RearrangementAnswer = 'RearrangementAnswer',
  RearrangementSpelling = 'RearrangementSpelling',
  Rearrangement = 'Rearrangement',
  TrueOrFalse = 'TrueOrFalse',
  FillTheGapMultiple = 'FillTheGapMultiple',
  FillTheGapMultipleSpelling = 'FillTheGapMultipleSpelling',

  // Book
  BookSection = 'BookSection',

  // Magic Trick App
  Trick = 'Trick', // TrickTutorial, TrickPerformance,  Trick
}

export enum CollectionTypeEnum {
  GenericCollection = 'GenericCollection',

  Vocabulary = 'Vocabulary',
  Grammar = 'Grammar',
  PlacementTest = 'PlacementTest',
  EvaluationTest = 'EvaluationTest',

  Listening = 'Listening',
  Speaking = 'Speaking',
  Reading = 'Reading',
  // Video = 'Video',
  VocabText = 'VocabText',
  Wizdom = 'Wizdom',
  TextFragm = 'TextFragm',

  // Magic Trick App
  TricksRoutine = 'TricksRoutine',

  Book = 'Book',
}

export enum TrueFalseEnum {
  True = 'True',
  False = 'False',
}

export enum QuizTypeEnum {
  Spelling = 'Spelling',
  MultipleChoice = 'MultipleChoice',
  MultipleChoiceFillTheGab = 'MultipleChoiceFillTheGab',
  MultipleChoiceImage = 'MultipleChoiceImage',
  Rearrangement = 'Rearrangement',
  RearrangementSpelling = 'RearrangementSpelling',
  RearrangementTranslate = 'RearrangementTranslate',
  Speech = 'Speech',
}

export const ItemGroups = {
  // lanlearn app
  Exercises: [
    ItemTypeEnum.MultipleChoice,
    ItemTypeEnum.QuestionAnswer,
    ItemTypeEnum.Rearrangement,
    ItemTypeEnum.RearrangementSpelling,
    ItemTypeEnum.RearrangementAnswer,
    ItemTypeEnum.TrueOrFalse,
    ItemTypeEnum.FillTheGapMultiple,
    ItemTypeEnum.FillTheGapMultipleSpelling,
  ],
  Vocabulary: [
    ItemTypeEnum.Word,
    ItemTypeEnum.Phrase,
    ItemTypeEnum.Quote,
    ItemTypeEnum.Idiom,
    ItemTypeEnum.Saying,
    ItemTypeEnum.Text,
  ],
  // Video: [ItemTypeEnum.Subtitle],

  // magictrick app
  Generic: [ItemTypeEnum.GenericItem],

  // magic
  MagicTricks: [ItemTypeEnum.Trick],
  Book: [ItemTypeEnum.BookSection],
};

export const CollectionItemMapping: {
  [key in CollectionTypeEnum]: Array<ItemTypeEnum>;
} = {
  // lanlearn app
  Grammar: ItemGroups.Exercises,
  Vocabulary: ItemGroups.Vocabulary,
  Listening: ItemGroups.Exercises,
  Speaking: ItemGroups.Vocabulary,
  Reading: ItemGroups.Exercises,
  PlacementTest: ItemGroups.Exercises,
  EvaluationTest: ItemGroups.Exercises,
  // Video: ItemGroups.Video,
  VocabText: ItemGroups.Vocabulary,
  Wizdom: [ItemTypeEnum.Quote],
  TextFragm: [ItemTypeEnum.Text],

  // magictrick app
  TricksRoutine: ItemGroups.MagicTricks,

  // generic app
  GenericCollection: ItemGroups.Generic,
  Book: ItemGroups.Book,
};

export const CollectionTypeNeedsDescription = Object.values(CollectionTypeEnum);

export const CollectionTypeNeedsQuizType = [
  CollectionTypeEnum.GenericCollection,
  CollectionTypeEnum.Vocabulary,
  CollectionTypeEnum.Speaking,
  CollectionTypeEnum.Wizdom,
  CollectionTypeEnum.TextFragm,
  CollectionTypeEnum.VocabText,
];

export const CollectionItemHasTags = [
  CollectionTypeEnum.GenericCollection,
  CollectionTypeEnum.Speaking,
  CollectionTypeEnum.Vocabulary,
  CollectionTypeEnum.Wizdom,
  CollectionTypeEnum.TextFragm,
  CollectionTypeEnum.VocabText,
];

// App Type Mapping
export const AppTypeCollectionMapping: {
  [key in AppTypes]: Array<CollectionTypeEnum>;
} = {
  [AppTypes.LanguageLearn]: [
    CollectionTypeEnum.Vocabulary,
    CollectionTypeEnum.Grammar,
    CollectionTypeEnum.Speaking,
    CollectionTypeEnum.Reading,
    CollectionTypeEnum.VocabText,
    CollectionTypeEnum.PlacementTest,
    CollectionTypeEnum.EvaluationTest,
    CollectionTypeEnum.Book,
    CollectionTypeEnum.Wizdom,
    CollectionTypeEnum.TextFragm,
  ],
  [AppTypes.MagicTricks]: [
    CollectionTypeEnum.TricksRoutine,
    CollectionTypeEnum.Book,
  ],
  [AppTypes.WorkoutFitness]: [CollectionTypeEnum.GenericCollection],
};

export const AppTypeItemMapping: {
  [key in AppTypes]: Array<ItemTypeEnum>;
} = {
  [AppTypes.LanguageLearn]: removeDuplicates(
    AppTypeCollectionMapping[AppTypes.LanguageLearn].reduce(
      (previous, current) => [...previous, ...CollectionItemMapping[current]],
      [] as ItemTypeEnum[]
    )
  ) as ItemTypeEnum[],
  [AppTypes.MagicTricks]: removeDuplicates(
    AppTypeCollectionMapping[AppTypes.MagicTricks].reduce(
      (previous, current) => [...previous, ...CollectionItemMapping[current]],
      [] as ItemTypeEnum[]
    )
  ) as ItemTypeEnum[],
  [AppTypes.WorkoutFitness]: removeDuplicates(
    AppTypeCollectionMapping[AppTypes.WorkoutFitness].reduce(
      (previous, current) => [...previous, ...CollectionItemMapping[current]],
      [] as ItemTypeEnum[]
    )
  ) as ItemTypeEnum[],
};
/****/

export const ItemTypeNameMapping: {
  [key in ItemTypeEnum]: {
    label: string;
  };
} = {
  // [ItemTypeEnum.BookSection]: { label: 'Section of a book' },
  // [ItemTypeEnum.FillTheGapMultiple]: { label: 'Fill in the gap' },
  // [ItemTypeEnum.FillTheGapMultipleSpelling]: {
  //   label: 'Fill in the gap (Listening)',
  // },
  // [ItemTypeEnum.GenericItem]: { label: '-' },
  // [ItemTypeEnum.MultipleChoice]: { label: 'ABCD questions' },
  // [ItemTypeEnum.Phrase]: { label: 'Phrase or Idiom' },
  // [ItemTypeEnum.QuestionAnswer]: {
  //   label: 'Answer the question or fill the gap',
  // },
  // [ItemTypeEnum.Rearrangement]: { label: 'Rearrange words/letters' },
  // [ItemTypeEnum.RearrangementAnswer]: {
  //   label: 'Answer the question by rearrange words/letters',
  // },
  // [ItemTypeEnum.RearrangementSpelling]: {
  //   label: 'Listen and rearrange words/letters',
  // },
  // [ItemTypeEnum.Subtitle]: { label: '-' },
  // [ItemTypeEnum.Trick]: { label: '-' },
  // [ItemTypeEnum.Word]: { label: 'Word' },
  // [ItemTypeEnum.TrueOrFalse]: { label: 'True / False statements' },

  [ItemTypeEnum.BookSection]: { label: 'Book Section' },
  [ItemTypeEnum.FillTheGapMultiple]: { label: 'Fill in the Gap' },
  [ItemTypeEnum.FillTheGapMultipleSpelling]: {
    label: 'Fill in the Gap (Spelling)',
  },
  [ItemTypeEnum.GenericItem]: { label: 'Generic Item' },
  [ItemTypeEnum.MultipleChoice]: { label: 'Multiple Choice (Gap/Question)' },
  [ItemTypeEnum.Phrase]: { label: 'Phrase' },
  [ItemTypeEnum.Idiom]: { label: 'Idiom' },
  [ItemTypeEnum.Saying]: { label: 'Saying' },
  [ItemTypeEnum.Quote]: { label: 'Quote' },
  [ItemTypeEnum.Sentence]: { label: 'Sentence' },

  [ItemTypeEnum.QuestionAnswer]: { label: 'Question and Answer' },
  [ItemTypeEnum.Rearrangement]: { label: 'Put Words/Letters in Sequence' },
  [ItemTypeEnum.RearrangementAnswer]: {
    label: 'Answer by Putting Words/Letters in Sequence',
  },
  [ItemTypeEnum.RearrangementSpelling]: {
    label: 'Listen and Put Words/Letters in Sequence',
  },
  // [ItemTypeEnum.Subtitle]: { label: 'Subtitle' },
  [ItemTypeEnum.Trick]: { label: 'Trick Question' },
  [ItemTypeEnum.Word]: { label: 'Word' },
  [ItemTypeEnum.TrueOrFalse]: { label: 'True / False statement' },
  [ItemTypeEnum.Text]: { label: 'Text fragment' },
};

export enum KnowledgeLevelEnum {
  VeryStrong = 'VeryStrong',
  Strong = 'Strong',
  Weak = 'Weak',
  Medium = 'Medium',
  NoLevel = 'NoLevel',
}

export const DefaultExerciseQuestionsMap: {
  [key in ItemTypeEnum]: string | null;
} = {
  [ItemTypeEnum.FillTheGapMultiple]: `Fill in the gaps.`,
  [ItemTypeEnum.FillTheGapMultipleSpelling]: `Listen and fill the gabs.`,
  [ItemTypeEnum.MultipleChoice]: `Select the correct answer.`,
  [ItemTypeEnum.Phrase]: null,
  [ItemTypeEnum.Sentence]: null,
  [ItemTypeEnum.Idiom]: null,
  [ItemTypeEnum.Saying]: null,
  [ItemTypeEnum.Quote]: null,
  [ItemTypeEnum.QuestionAnswer]: `Write the correct answer.`,
  [ItemTypeEnum.Rearrangement]: `Put the words to the correct order.`,
  [ItemTypeEnum.TrueOrFalse]: `Choose if the sentence is correct.`,
  [ItemTypeEnum.Word]: null,
  [ItemTypeEnum.RearrangementAnswer]: `Put the words to the correct order.`,
  [ItemTypeEnum.Text]: null,
  [ItemTypeEnum.RearrangementSpelling]: `Listen and put the words to the correct order.`,
  [ItemTypeEnum.GenericItem]: null,
  [ItemTypeEnum.Trick]: null,
  [ItemTypeEnum.BookSection]: null,
};
