import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';

export interface IState {
  removeNamespaceStatus: StateStatus | null;
  addNamespaceStatus: StateStatus | null;
  editNamespaceStatus: StateStatus | null;
}

const initialState: IState = {
  removeNamespaceStatus: null,
  addNamespaceStatus: null,
  editNamespaceStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.REMOVE_NAMESPACE:
      return {
        ...state,
        removeNamespaceStatus: action.status || null,
      };
    case Types.EDIT_NAMESPACE:
      return {
        ...state,
        editNamespaceStatus: action.status || null,
      };
    case Types.ADD_NAMESPACE:
      return {
        ...state,
        addNamespaceStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
