import { TagSubTypes } from './tagsConst';
import { CollectionTypeEnum, ItemTypeEnum } from './typesConst';

export enum Namespacestatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

// or namespace language
export enum NamespaceType {
  // Language Learn App
  English = 'English',
  Greek = 'Greek',
  Italian = 'Italian',
  German = 'German',
  French = 'French',
  Spanish = 'Spanish',
  Portuguese = 'Portuguese',
  Russian = 'Russian',
  Swedish = 'Swedish',
  Ukrainian = 'Ukrainian',
  Polish = 'Polish',
  Dutch = 'Dutch',
  Serbian = 'Serbian',
  Croatian = 'Croatian',
  Bulgarian = 'Bulgarian',
  Slovenian = 'Slovenian',
  Hungarian = 'Hungarian',
  Turkish = 'Turkish',

  // Default
  Other = 'Other',
}

type IGoogleTranslateNamespaceTypeMap = {
  [key in NamespaceType]: string;
};

// language codes: https://cloud.google.com/translate/docs/languages
export const GoogleTranslateNamespaceTypeMap: IGoogleTranslateNamespaceTypeMap =
  {
    English: 'en',
    Greek: 'el',
    Italian: 'it',
    German: 'de',
    French: 'fr',
    Spanish: 'es',
    Portuguese: 'pt',
    Russian: 'ru',
    Swedish: 'sv',
    Ukrainian: 'uk',
    Polish: 'pl',
    Dutch: 'nl',
    Serbian: 'sr',
    Croatian: 'hr',
    Bulgarian: 'bg',
    Slovenian: 'sl',
    Hungarian: 'hu',
    Turkish: 'tr',
    Other: 'en',
  };

type IWordReferebceNamespaceTypeMap = {
  [key in NamespaceType]: string;
};

// language codes: https://cloud.google.com/translate/docs/languages
export const WordReferenceNamespaceTypeMap: IWordReferebceNamespaceTypeMap = {
  English: 'engr',
  Greek: 'gren',
  Italian: 'itgr',
  German: 'degr',
  French: 'frgr',
  Spanish: 'esgr',
  Portuguese: 'ptgr',
  Russian: 'rugr',
  Swedish: 'svgr',
  Ukrainian: 'ukgr',
  Polish: 'plgr',
  Dutch: 'nlgr',
  Serbian: 'srgr',
  Croatian: 'hrgr',
  Bulgarian: 'bggr',
  Slovenian: 'slgr',
  Hungarian: 'hugr',
  Turkish: 'trgr',
  Other: 'engr',
};

export enum AppTypes {
  LanguageLearn = 'LanguageLearn',
  MagicTricks = 'MagicTricks',
  WorkoutFitness = 'WorkoutFitness',
}

type IAppTypeNamespaceTypeMapping = {
  [key in AppTypes]: Array<NamespaceType>;
};

export const AppTypeNamespaceTypeMapping: IAppTypeNamespaceTypeMapping = {
  [AppTypes.LanguageLearn]: Object.values(NamespaceType).filter(
    (el) => el !== NamespaceType.Other
  ),
  [AppTypes.MagicTricks]: [NamespaceType.Other],
  [AppTypes.WorkoutFitness]: [NamespaceType.Other],
};

export enum FeaturesTypes {
  ExploreItemCategories = 'ItemCategories',
  ExploreCollectionCategories = 'CollectionCategories',
  Library = 'Library',
  Practice = 'Practice',
  Profile = 'Profile',
  Settings = 'Settings',
  SkillBuilding = 'SkillBuilding', // Discover = 'Discover',
  PersonalPlan = 'PersonalPlan',
}
// export enum FeaturesTypes {
//   ItemCategories = 'ItemCategories',
//   Library = 'Library',
//   Practice = 'Practice',
//   Profile = 'Profile',
//   Settings = 'Settings',
//   Discover = 'Discover',
//   Explore = 'Explore',
//   PersonalPlan = 'PersonalPlan',
// }

export const DefaultFeatureTypes = [
  FeaturesTypes.PersonalPlan,
  FeaturesTypes.SkillBuilding, //  FeaturesTypes.Explore
  FeaturesTypes.Practice,
  FeaturesTypes.Profile,
];

export const NamespaceStatusOptions = Object.values(Namespacestatus).map(
  (el) => ({
    label: el,
    id: el,
  })
);

export const NamespaceTypeOptions = Object.values(NamespaceType).map((el) => ({
  label: el,
  id: el,
}));

export const SubTypeNameMapping: {
  [key in TagSubTypes]: {
    label: string;
  };
} = {
  [TagSubTypes.CollectionBooks]: { label: 'Books Section' },
  [TagSubTypes.CollectionExpCategories]: { label: 'Categories Section' },
  [TagSubTypes.CollectionLibraryTags]: { label: 'Library Section' },
  [TagSubTypes.CollectionPersonalPlan]: { label: 'Personal-Plan Section' },
  [TagSubTypes.Hidden]: { label: 'Hidden' },
  [TagSubTypes.ItemExpCategories]: { label: 'Categories Section' },
  [TagSubTypes.ItemLibraryTags]: { label: 'Library Section' },
};

// export const NamespaceSubTypeOptions = Object.values(TagSubTypes).map((el) => ({
//   id: el,
//   label: SubTypeNameMapping[el].label,
// }));
