import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import reactLogger from 'utils/logger';
import * as FilesService from 'services/api/file';
import { IState } from './reducer';
import { FilePathTypes } from 'constants/filesConst';

/** Remove File  */
interface IRemoveFilePayload {
  fileId: string;
  onSuccess: () => void;
}

class RemoveFile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REMOVE_FILE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.REMOVE_FILE,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REMOVE_FILE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IRemoveFilePayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await FilesService.removeFile(payload.fileId);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('Remove File:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Files  */
interface IFetchFilesPayload {
  payload: FilesService.IFetchFilesPayload;
  onSuccess: () => void;
}

class FetchFiles implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_FILES,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: IState['filesList']['data']): IAction {
    return {
      type: Types.SET_FILES,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_FILES,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IFetchFilesPayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const result = await FilesService.fetchFilesList(payload.payload);
        payload.onSuccess();
        dispatch(this.onSuccess(result.data));
      } catch (error) {
        reactLogger.error('Fetch Files:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Upload File  */
interface IUploadFilePayload {
  data: FilesService.IUploadSingleFilePayload;
  onSuccess: () => void;
}

class UploadFile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPLOAD_FILE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.UPLOAD_FILE,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPLOAD_FILE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IUploadFilePayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await FilesService.uploadSingleFile(payload.data);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('Upload File:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Edit File  */
interface IEditFilePayload {
  fileId: string;
  onSuccess: () => void;
  payload: FilesService.IEditFilePayload;
}

class EditFile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_FILE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.EDIT_FILE,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_FILE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IEditFilePayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await FilesService.editFile(payload.fileId, payload.payload);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('Edit File:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Upload File From URL  */
interface IUploadFileFromURLPayload {
  data: {
    url: string;
  };
  onSuccess: () => void;
}

class UploadFileFromURL implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPLOAD_FILE_FROM_URL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.UPLOAD_FILE_FROM_URL,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPLOAD_FILE_FROM_URL,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IUploadFileFromURLPayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await FilesService.uploadSingleFileByUrl({
          fileUrl: payload.data.url,
          path: FilePathTypes.AppFiles,
        });
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('Upload File From URL:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  editFileAction: (payload: IEditFilePayload) => new EditFile().action(payload),
  removeFileAction: (payload: IRemoveFilePayload) =>
    new RemoveFile().action(payload),
  fetchFilesAction: (payload: IFetchFilesPayload) =>
    new FetchFiles().action(payload),
  uploadFileAction: (payload: IUploadFilePayload) =>
    new UploadFile().action(payload),
  uploadFileFromURLAction: (payload: IUploadFileFromURLPayload) =>
    new UploadFileFromURL().action(payload),
};
