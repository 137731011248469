import { ItemStatusEnum, CollectionStatusEnum } from './statusConst';
import { TagLevel } from './tagsConst';
import { CollectionTypeEnum, QuizTypeEnum } from './typesConst';

export enum BulkStatusEnum {
  BulkActionActive = 'BulkActionActive',
  BulkActionInactive = 'BulkActionInactive',
  BulkActionOnReview = 'BulkActionOnReview',
  BulkActionAIReview = 'BulkActionAIReview',
}

export enum BulkAccessEnum {
  Public = 'Public',
  Private = 'Private',
}

// item bulk status map
type IItemBulkStatusMap = {
  [key in BulkStatusEnum]: ItemStatusEnum;
};

export const ItemBulkStatusMap: IItemBulkStatusMap = {
  BulkActionAIReview: ItemStatusEnum.AIReview,
  BulkActionActive: ItemStatusEnum.Active,
  BulkActionInactive: ItemStatusEnum.Inactive,
  BulkActionOnReview: ItemStatusEnum.OnReview,
};

// collectio nulk status map
type ICollectionBulkStatusMap = {
  [key in BulkStatusEnum]: CollectionStatusEnum;
};

export const CollectionBulkStatusMap: ICollectionBulkStatusMap = {
  BulkActionAIReview: CollectionStatusEnum.AIReview,
  BulkActionActive: CollectionStatusEnum.Active,
  BulkActionInactive: CollectionStatusEnum.Inactive,
  BulkActionOnReview: CollectionStatusEnum.OnReview,
};

// access
type IItemBulkAccessMap = {
  [key in BulkAccessEnum]: boolean;
};

type IColelctionBulkAccessMap = {
  [key in BulkAccessEnum]: boolean;
};

export const ItemBulkPublicityMap: IItemBulkAccessMap = {
  Public: true,
  Private: false,
};

export const CollectionBulkPublicityMap: IColelctionBulkAccessMap = {
  Public: true,
  Private: false,
};

type ICollectionBulkNamesMap = {
  [key in BulkStatusEnum]: string;
};

export const CollectionBulkStatusNames: ICollectionBulkNamesMap = {
  BulkActionAIReview: 'AIReview',
  BulkActionActive: 'Active',
  BulkActionInactive: 'Inactive',
  BulkActionOnReview: 'OnReview',
};

export const QuizzesByLevelMap: {
  [key in TagLevel]: QuizTypeEnum[];
} = {
  [TagLevel.Level1]: [
    QuizTypeEnum.MultipleChoice,
    QuizTypeEnum.MultipleChoiceFillTheGab,
    QuizTypeEnum.MultipleChoiceImage,
    QuizTypeEnum.RearrangementTranslate,
    QuizTypeEnum.RearrangementSpelling,
  ],
  [TagLevel.Level2]: [
    QuizTypeEnum.MultipleChoice,
    QuizTypeEnum.MultipleChoiceFillTheGab,
    QuizTypeEnum.MultipleChoiceImage,
    QuizTypeEnum.Rearrangement,
    QuizTypeEnum.RearrangementTranslate,
  ],
  [TagLevel.Level3]: [
    QuizTypeEnum.MultipleChoice,
    QuizTypeEnum.MultipleChoiceFillTheGab,
    QuizTypeEnum.MultipleChoiceImage,
    QuizTypeEnum.Rearrangement,
    QuizTypeEnum.RearrangementTranslate,
    QuizTypeEnum.Spelling,
  ],
  [TagLevel.Level4]: [
    QuizTypeEnum.MultipleChoice,
    QuizTypeEnum.MultipleChoiceFillTheGab,
    QuizTypeEnum.MultipleChoiceImage,
    QuizTypeEnum.Rearrangement,
    QuizTypeEnum.RearrangementTranslate,
  ],
  [TagLevel.Level5]: [
    QuizTypeEnum.MultipleChoice,
    QuizTypeEnum.MultipleChoiceFillTheGab,
    QuizTypeEnum.MultipleChoiceImage,
    QuizTypeEnum.Rearrangement,
    QuizTypeEnum.RearrangementTranslate,
    QuizTypeEnum.Speech,
  ],
  [TagLevel.Level6]: [
    QuizTypeEnum.MultipleChoice,
    QuizTypeEnum.MultipleChoiceFillTheGab,
    QuizTypeEnum.MultipleChoiceImage,
    QuizTypeEnum.Rearrangement,
    QuizTypeEnum.RearrangementTranslate,
    QuizTypeEnum.Speech,
  ],
  [TagLevel.Level7]: [
    QuizTypeEnum.MultipleChoice,
    QuizTypeEnum.MultipleChoiceFillTheGab,
    QuizTypeEnum.MultipleChoiceImage,
    QuizTypeEnum.Rearrangement,
    QuizTypeEnum.RearrangementTranslate,
  ],
  [TagLevel.Level8]: [
    QuizTypeEnum.MultipleChoice,
    QuizTypeEnum.MultipleChoiceFillTheGab,
    QuizTypeEnum.MultipleChoiceImage,
    QuizTypeEnum.Rearrangement,
    QuizTypeEnum.RearrangementTranslate,
  ],
};

// export const QuizzesByTypeMap: {
//   [key in CollectionTypeEnum]?: QuizTypeEnum[] | null;
// } = {
//   [CollectionTypeEnum.Vocabulary]: Object.values(QuizTypeEnum),
//   [CollectionTypeEnum.Speaking]: Object.values(QuizTypeEnum).filter(
//     (el) =>
//       ![
//         QuizTypeEnum.MultipleChoiceImage,
//         QuizTypeEnum.MultipleChoiceFillTheGab,
//       ].includes(el)
//   ),
// };

export const QuizzesByTypeMap: {
  [key in CollectionTypeEnum]?: QuizTypeEnum[] | null;
} = {
  [CollectionTypeEnum.Vocabulary]: Object.values(QuizTypeEnum).filter(
    (el) => ![QuizTypeEnum.Rearrangement, QuizTypeEnum.Speech].includes(el)
  ),
  [CollectionTypeEnum.Wizdom]: [QuizTypeEnum.MultipleChoice],
  [CollectionTypeEnum.Speaking]: Object.values(QuizTypeEnum).filter(
    (el) =>
      ![
        QuizTypeEnum.MultipleChoiceImage,
        QuizTypeEnum.MultipleChoiceFillTheGab,
      ].includes(el)
  ),
  [CollectionTypeEnum.TextFragm]: [
    QuizTypeEnum.Rearrangement,
    QuizTypeEnum.RearrangementSpelling,
  ],
};
