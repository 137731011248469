import { useDispatch } from 'react-redux';
import React from 'react';
import UploadedFilesAction from '../../store/actions';
import {
  FilePathTypes,
  FileType,
  FileTypeActionsMapping,
} from 'constants/filesConst';
import { DefaultPaging } from '../../configuration';
import {
  alpha,
  Box,
  Button,
  ButtonBase,
  colors,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import TranslatedText from 'components/atoms/TranslatedText';
import EditIcon from '@material-ui/icons/Edit';
import BackupIcon from '@material-ui/icons/Backup';

interface IUploadFileButtonProps {
  fileId?: string;
  fileType?: FileType;
}

const UploadFileButton = ({ fileId, fileType }: IUploadFileButtonProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: any) => {
    const file = event?.target?.files?.[0];

    // upload file
    dispatch(
      UploadedFilesAction.uploadFileAction({
        data: {
          path: FilePathTypes.AppFiles,
          file,
          fileId,
        },
        onSuccess: () => {
          dispatch(
            UploadedFilesAction.fetchFilesAction({
              payload: DefaultPaging,
              onSuccess: () => {},
            })
          );
        },
      })
    );

    console.log('Set values:', file);
  };

  return (
    <>
      {fileId ? (
        <Box style={{ position: 'absolute', left: 0, bottom: 0, zIndex: 10 }}>
          <IconButton
            component="label"
            style={{
              background: alpha(colors.common.black, 0.8),
              color: colors.common.white,
            }}
            size="small"
          >
            <input
              hidden
              accept={
                FileTypeActionsMapping?.[fileType as FileType]?.accept ??
                'image/*'
              }
              type="file"
              onChange={handleChange}
            />
            <BackupIcon style={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      ) : (
        <Box
          style={{ marginLeft: downSm ? 0 : '0.5rem', marginRight: '0.5rem' }}
        >
          <Button
            startIcon={<BackupOutlinedIcon />}
            component="label"
            variant="outlined"
          >
            <input
              hidden
              accept={
                FileTypeActionsMapping?.[fileType as FileType]?.accept ??
                'image/*'
              }
              type="file"
              onChange={handleChange}
            />
            <TranslatedText defaultText={'Upload File'} />
          </Button>
        </Box>
      )}
    </>
  );
};

export default UploadFileButton;
