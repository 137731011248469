import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppTextField,
  AppSelect,
  IMultipleSelectProps,
  AppCommon,
  IAdvancedSelectProps,
  AppAdvancedselect,
  AppSwitch,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useWatch,
  useForm,
  useFormContext,
} from 'react-hook-form';
import _ from 'lodash';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import { AuthRoles, Gender } from 'constants/index';
import GridLayout, {
  IElement,
  IElementsProps,
} from 'components/molecules/GridLayout';
import { colors, IconButton, Paper, useTheme } from '@material-ui/core';
import RoutineDetailsActions from '../../store/actions';
import * as RoutineDetailsSelectors from '../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import { NoNullFields, OverrideValues } from 'utils/generics';
import NavigationConfig from 'config/navigationConfig';
import { IFormData, VectorIconsList, defaultValues } from '../../configuration';
import TrickList from '../ItemsList';
import TrickListModal, { TricksListModalParams } from '../ItemsListModal';
import { withVerification } from 'components/molecules/ModalVerifyAction';
import {
  downloadCsvCollection,
  exportCollectionExercises,
  exportCollectionText,
} from 'services/api/collections';
import RichTextEditor, {
  convertDraftToHtml,
  convertHtmlToDraft,
} from 'components/atoms/RichTextEditor';
import {
  CollectionTypeEnum,
  CollectionTypeNeedsQuizType,
  ItemGroups,
  ItemTypeEnum,
  QuizTypeEnum,
} from 'constants/typesConst';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import TagDetailsModal, {
  openTagDetailsModal,
  closeTagDetailsModal,
} from 'components/compounds/TagDetailsModal';
import { TagLevel, TagSubTypes, TagTypes } from 'constants/tagsConst';
import { EditorState } from 'draft-js';
import {
  useSelectedPortalNamespace,
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import {
  openTranslationModalFormModal,
  ITranslationsModalFormParams,
  SelectedLanguages,
} from 'components/app_common/TranslationsModal';
import TranslateIcon from '@material-ui/icons/Translate';
import { DefaultLanguage, LanguagesEnum } from 'constants/languagesConst';
import {
  fixTranslations,
  getFieldTranslations,
  translation,
} from 'utils/translation';
import { useNamespacesList } from 'redux/reducers/gl_common_reducer/selectors';
import { PaymentTypeEnum, PaymentTypeOptions } from 'constants/paymentsConst';
import {
  CollectionStatusEnum,
  CollectionStatusOptions,
} from 'constants/statusConst';
import { CollectionTypeOptions } from 'constants/dropdownOptions';
import {
  closeUploadedFilesModal,
  openUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {
  getFileDetails,
  openFileToNewTab,
  openFileToNewTabAsync,
} from 'services/api/file';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { FileType } from 'constants/filesConst';
import SelectButton from 'components/molecules/SelectButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import LanguageIcon from '@material-ui/icons/Language';
import { getQuizzesByChapterType } from '../../common';
import { Roles } from 'constants/authRolesConst';
import QuizTypesButton from './QuizTypesButton';
import OptionsMenu from 'components/atoms/OptionsMenu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import TranscriptTextModal from './TranscriptTextModal';
import VideoActionsMenu from './VideoActionsMenu';
import ImageUrlSection from './ImageUrlSection';
import { useContextProps } from '../../provider';
import { IAddEditCollectionFormProps } from '../../types';
import AudioUrlSection from './AudioUrlSection';
import { arraysHasCommonElements, groupOptions } from 'utils/arrays';
import useTextSelector from 'hooks/useTextSelector';
import { AppTypes } from 'constants/namespaceConst';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { openColLibrariesPerCollectionModal } from 'components/compounds/ColLibrariesPerCollectionModal';
import ExportCollectionButton from '../ExportCollectionButton';
import { getState } from 'redux/store';

export default function AddEditTrickForm({
  config,
}: IAddEditCollectionFormProps) {
  const classes = useStyles({});
  const trickDetails = RoutineDetailsSelectors.useRoutineDetails();
  const {
    reset,
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
    watch,
  } = useFormContext<IFormData>();
  // const params = useParams<ITrickDetailsPageParams>();
  const { ...params } = useContextProps();
  const dispatch = useDispatch();
  const history = useHistory();
  const tricksRef = React.useRef<IFormData['items']>();
  const descriptionEditorStateRef = React.useRef<EditorState | null>(null);
  const namespaces = useNamespacesList();
  const namespacesOptions = React.useMemo(
    () => namespaces.data?.map((el) => ({ id: el._id, label: el.name })) ?? [],
    [namespaces]
  );
  const selectedNamespace = useSelectedPortalNamespace();
  const collectionType = watch('type');
  const roles = useSelectUserRoles();
  const routineId = params.collectionId;
  const { textSelector } = useTextSelector();

  const initialRichText = React.useMemo(
    () => convertHtmlToDraft(trickDetails.data?.description ?? ''),
    [trickDetails]
  );

  console.log('render form:', trickDetails.status);

  React.useEffect(() => {
    if (trickDetails.data) {
      reset({
        ...defaultValues,
        title: trickDetails.data.title,
        description: trickDetails.data.description,
        type: trickDetails.data.type as CollectionTypeEnum,
        link: trickDetails.data.link,
        quizTypes: trickDetails.data.quizTypes as QuizTypeEnum[],
        tags: trickDetails.data.tags ?? [],
        public: trickDetails.data.public ? 'true' : 'false',
        isTemplate: trickDetails.data.isTemplate,
        items: trickDetails.data.items?.map(
          (item) =>
            ({
              id: item?._id,
              title: item?.title,
              tags: item?.tags,
              status: item.status,
              type: item.type as ItemTypeEnum,
              difficulty: item.difficulty,
              public: item.public,
            } ?? [])
        ),
        namespaces: trickDetails.data.namespaces ?? null,
        imageId: trickDetails.data.imageId?._id ?? null,
        translations: trickDetails.data.translations,
        status: trickDetails.data.status,
        paymentType: trickDetails.data.paymentType,
        difficulty: trickDetails.data.difficulty,
        audioId: trickDetails.data.audioId?._id ?? null,
        maskAnswers: trickDetails.data.maskAnswers ?? null,
      });
      register('translations');
    } else {
      setValue('namespaces', [selectedNamespace]);
    }
  }, [trickDetails, reset, selectedNamespace]);

  const handleTranslation = React.useCallback(
    (
      name: string,
      title: string,
      options?: Partial<ITranslationsModalFormParams>
    ) => {
      openTranslationModalFormModal({
        onSubmit: (data) => {
          Object.keys(data).forEach((lan) => {
            const key = `translations.${lan}.${name}`;
            register(key);
            setValue(key, data[lan as LanguagesEnum]);
          });
        },
        type: 'text',
        title,
        initialData: getFieldTranslations(name, getValues() as any) as any,
        modalSize: 'xlarge',
        autoTranslation: {
          textToTranslate: _.get(getValues(), name),
          execute: async (text, lanTo) => {
            return await translation(text, {
              lanTo,
              lanFrom: 'defaultLan',
            });
          },
        },
        ...options,
      });
    },
    []
  );

  const onSubmit = handleSubmit((_data: NoNullFields<IFormData>) => {
    const description =
      config.senctionDescription?.type === 'textarea'
        ? _data.description
        : descriptionEditorStateRef.current
        ? convertDraftToHtml(descriptionEditorStateRef.current)
        : '';

    if (params.mode === 'add') {
      dispatch(
        RoutineDetailsActions.addRoutineAction({
          payload: {
            ..._data,
            tags: _data.tags ?? null,
            description,
            type: _data.type,
            link: _data.link ?? undefined,
            public: _data.public === 'false' ? false : true, // collIsPublic,
            items: tricksRef.current?.map((el) => el.id) ?? [],
            namespaces: _data.namespaces ?? [],
            imageId: _data.imageId ?? undefined,
            audioId: _data.audioId ?? undefined,
            translations: fixTranslations(_data.translations),
            difficulty: _data.difficulty
              ? parseFloat(`${_data.difficulty}`)
              : undefined,
            quizTypes:
              (_data.quizTypes?.length ?? 0) === 0
                ? getQuizzesByChapterType(_data.type) ?? undefined
                : undefined,
            maskAnswers: _data.maskAnswers ?? undefined,
            isTemplate: _data.isTemplate ?? undefined,
          },
          onSuccess: () => {
            history.push(NavigationConfig.routinesPage().path);
          },
        })
      );
    } else if (params.mode === 'edit') {
      // console.log('Payload::', _data);
      dispatch(
        RoutineDetailsActions.editRoutineAction({
          payload: {
            ..._data,
            description,
            type: _data.type,
            link: _data.link ?? undefined,
            public: _data.public === 'false' ? false : true,
            // collIsPublic,
            items: tricksRef.current?.map((el) => el.id) ?? [],
            namespaces: _data.namespaces ?? [],
            imageId: _data.imageId,
            audioId: _data.audioId ?? null,
            translations: fixTranslations(_data.translations),
            difficulty: _data.difficulty
              ? parseFloat(`${_data.difficulty}`)
              : null,
            maskAnswers: _data.maskAnswers ?? undefined,
            isTemplate: _data.isTemplate ?? undefined,
          },
          onSuccess: () => {
            dispatch(
              RoutineDetailsActions.fetchRoutineDetailsAction(
                routineId as string
              )
            );
          },
          routineId: routineId as string,
        })
      );
    }
  });

  return (
    <>
      <TranscriptTextModal />
      <TrickListModal setValue={setValue} />
      <TagDetailsModal />
      <div className={classes.form}>
        <AppCommon.FormContainer>
          {config.sectionTitle?.visibility && (
            <AppCommon.Section
              iconLeft={
                <IconButton
                  onClick={() => {
                    handleTranslation('title', 'Title');
                  }}
                  size="small"
                  style={{ marginRight: '0.3rem' }}
                >
                  <LanguageIcon />
                </IconButton>
              }
              title="Title"
              footer={<SelectedLanguages fieldName="title" control={control} />}
            >
              <GridLayout
                justify="flex-start"
                elements={[
                  ...(config.sectionTitle.visibility.includeTitle
                    ? ([
                        {
                          id: 'title',
                          element: (
                            <AppTextField
                              variant="outlined"
                              margin="normal"
                              size="medium"
                              error={'title' in errors}
                              helperText={errors.title && errors.title.message}
                              rules={{ required: <AppCommon.RequiredField /> }}
                              required
                              fullWidth
                              id="title"
                              control={control}
                              placeholder="Enter Title.."
                              autoComplete="title"
                              name="title"
                            />
                          ),
                          size: 12,
                        },
                      ] as IElementsProps[])
                    : []),
                ]}
              />
            </AppCommon.Section>
          )}

          {config.senctionTags?.visibility?.includeTags && (
            <AppCommon.Section
              title={textSelector('Tags', {
                [AppTypes.LanguageLearn]: 'Course Units',
              })}
            >
              <TagsSelectorWrapper
                id="tags"
                control={control}
                placeholder={textSelector('Select Tags..', {
                  [AppTypes.LanguageLearn]: 'Select Units..',
                })}
                name="tags"
                helperText={errors.tags && errors.tags.message}
                rules={{ required: <AppCommon.RequiredField /> }}
                margin="normal"
                error={'tags' in errors}
              />
            </AppCommon.Section>
          )}

          {config.senctionImage?.visibility?.includeImage && (
            <ImageUrlSection />
          )}

          {config.senctionAudio?.visibility?.includeAudio && (
            <AudioUrlSection />
          )}

          {config.senctionBasicInfo?.visibility && (
            <AppCommon.Section
              contentIsVisible={params.mode === 'edit'}
              title="Basic Info"
            >
              <GridLayout
                justify="flex-start"
                elements={[
                  ...(config.senctionBasicInfo.visibility.includeStatus
                    ? ([
                        {
                          id: 'status',
                          element: (
                            <AppSelect
                              variant="outlined"
                              size="medium"
                              margin="normal"
                              error={'status' in errors}
                              helperText={
                                errors.status && errors.status.message
                              }
                              rules={{ required: <AppCommon.RequiredField /> }}
                              required
                              fullWidth
                              id="status"
                              label={<TranslatedText defaultText={'Status'} />}
                              control={control}
                              autoComplete="status"
                              name="status"
                              options={CollectionStatusOptions}
                            />
                          ),
                          // collectionType === null ? 4 : 6,
                          size: 12,
                          md: 12,
                        },
                      ] as IElementsProps[])
                    : []),

                  ...(config.senctionBasicInfo.visibility.includePublic
                    ? ([
                        {
                          id: 'public',
                          element: (
                            <AppSelect
                              variant="outlined"
                              size="medium"
                              margin="normal"
                              error={'public' in errors}
                              helperText={
                                errors.public && errors.public.message
                              }
                              rules={{ required: <AppCommon.RequiredField /> }}
                              required
                              fullWidth
                              disabled={
                                !arraysHasCommonElements(roles, [
                                  Roles.Admin,
                                  Roles.Supervisor,
                                ])
                              }
                              id="public"
                              label={<TranslatedText defaultText={'Access'} />}
                              control={control}
                              autoComplete="public"
                              name="public"
                              options={[
                                {
                                  id: 'true',
                                  label: 'Public',
                                },
                                {
                                  id: 'false',
                                  label: 'Private',
                                },
                              ]}
                            />
                          ),
                          size: 12,
                        },
                      ] as IElementsProps[])
                    : []),

                  ...(config.senctionBasicInfo.visibility.includeType
                    ? ([
                        {
                          id: 'type',
                          element: (
                            <AppSelect
                              variant="outlined"
                              size="medium"
                              margin="normal"
                              error={'type' in errors}
                              helperText={errors.type && errors.type.message}
                              rules={{ required: <AppCommon.RequiredField /> }}
                              required
                              fullWidth
                              style={{
                                display:
                                  collectionType === null || Boolean(routineId)
                                    ? 'block'
                                    : 'none',
                              }}
                              id="type"
                              label={<TranslatedText defaultText={'Type'} />}
                              control={control}
                              autoComplete="type"
                              name="type"
                              options={CollectionTypeOptions}
                            />
                          ),
                          size: 12,
                        },
                      ] as IElementsProps[])
                    : []),

                  ...(config.senctionBasicInfo.visibility.includeQuizTypes
                    ? ([
                        {
                          id: 'quizTypes',
                          element: (
                            <QuizTypesWrapper
                              id="quizTypes"
                              control={control}
                              placeholder="Quiz Types"
                              name="quizTypes"
                              rules={{}}
                              margin="normal"
                              isMulti
                              options={QuizTypes}
                              helperText={
                                errors.quizTypes &&
                                (errors.quizTypes as any).message
                              }
                            />
                          ),
                          size: 12,
                          md: 12,
                        },
                      ] as IElementsProps[])
                    : []),

                  ...(config.senctionBasicInfo.visibility.includeNamespaces
                    ? ([
                        {
                          id: 'namespaces',
                          element: (
                            <AppAdvancedselect
                              control={control}
                              name="namespaces"
                              disabled={
                                !arraysHasCommonElements(roles, [
                                  Roles.Admin,
                                  Roles.Supervisor,
                                ])
                              }
                              placeholder="Workspaces"
                              error={'namespaces' in errors}
                              id={'namespaces'}
                              rules={{}}
                              margin={'normal'}
                              isMulti
                              helperText={
                                errors.namespaces && errors.namespaces.message
                              }
                              options={namespacesOptions}
                            />
                          ),
                          size: 12,
                          rowStyle: {
                            marginBottom: 0,
                          },
                        },
                      ] as IElementsProps[])
                    : []),

                  ...(config.senctionBasicInfo.visibility.includeLink
                    ? ([
                        {
                          id: 'link',
                          element: (
                            <AppTextField
                              variant="outlined"
                              size="medium"
                              margin="normal"
                              error={'link' in errors}
                              helperText={errors.link && errors.link.message}
                              rules={{}}
                              fullWidth
                              id="link"
                              label={
                                <TranslatedText defaultText={'Video url'} />
                              }
                              control={control}
                              autoComplete="link"
                              icon={{
                                ComponentRight: (
                                  // <IconButton
                                  //   onClick={() =>
                                  //     getValues().link &&
                                  //     window.open(getValues().link ?? '', '_blank')
                                  //   }
                                  // >
                                  //   <VideocamOutlinedIcon />
                                  // </IconButton>
                                  <VideoActionsMenu />
                                ),
                              }}
                              name="link"
                            />
                          ),
                          size: 12,
                        },
                      ] as IElementsProps[])
                    : []),

                  {
                    id: 'paymentType',
                    element: (
                      <AppSelect
                        variant="outlined"
                        size="medium"
                        margin="normal"
                        error={'paymentType' in errors}
                        helperText={
                          errors.paymentType && errors.paymentType.message
                        }
                        rules={{ required: <AppCommon.RequiredField /> }}
                        required
                        fullWidth
                        id="paymentType"
                        label={<TranslatedText defaultText={'PaymentType'} />}
                        control={control}
                        autoComplete="paymentType"
                        name="paymentType"
                        options={PaymentTypeOptions}
                      />
                    ),
                    size: 12,
                    md: 12,
                  },

                  ...(config.senctionBasicInfo.visibility.includeDifficulty
                    ? ([
                        {
                          id: 'difficulty',
                          element: (
                            <AppTextField
                              variant="outlined"
                              margin="normal"
                              size="medium"
                              error={'difficulty' in errors}
                              helperText={
                                errors.difficulty && errors.difficulty.message
                              }
                              rules={{}}
                              fullWidth
                              id="difficulty"
                              control={control}
                              label="Difficulty"
                              autoComplete="difficulty"
                              name="difficulty"
                              type="number"
                            />
                          ),
                          size: 12,
                          md: 12,
                        },
                      ] as IElementsProps[])
                    : []),

                  ...(config.senctionBasicInfo.visibility.includeMaskAnswers
                    ? ([
                        {
                          id: 'maskAnswers',
                          element: (
                            <AppSwitch
                              variant="outlined"
                              size="medium"
                              margin="normal"
                              rules={{ required: <AppCommon.RequiredField /> }}
                              required
                              id="maskAnswers"
                              label={
                                <TranslatedText defaultText={'Mask answers'} />
                              }
                              control={control}
                              name="maskAnswers"
                            />
                          ),
                          size: 12,
                        },
                      ] as IElementsProps[])
                    : []),

                  ...(config.senctionBasicInfo.visibility.includeIsTemplate
                    ? ([
                        {
                          id: 'isTemplate',
                          element: (
                            <AppSwitch
                              variant="outlined"
                              size="medium"
                              margin="normal"
                              rules={{ required: <AppCommon.RequiredField /> }}
                              required
                              id="isTemplate"
                              label={
                                <TranslatedText defaultText={'Is Template'} />
                              }
                              control={control}
                              name="isTemplate"
                            />
                          ),
                          size: 12,
                        },
                      ] as IElementsProps[])
                    : []),
                ]}
              />
            </AppCommon.Section>
          )}

          {config.senctionDescription?.visibility?.includeDescription && (
            <AppCommon.Section
              // contentIsVisible={params.mode === 'edit'}
              title="Description"
              footer={
                <SelectedLanguages fieldName="description" control={control} />
              }
              iconLeft={
                <IconButton
                  onClick={() => {
                    handleTranslation('description', 'Description', {
                      type:
                        config?.senctionDescription?.type === 'textarea'
                          ? 'textarea'
                          : 'richtext',
                      tabs: true,
                      modalSize: 'xlarge',
                      autoTranslation: {
                        textToTranslate: descriptionEditorStateRef.current
                          ? convertDraftToHtml(
                              descriptionEditorStateRef.current
                            )
                          : '',
                        execute: async (text, lanTo) => {
                          return await translation(text, {
                            lanTo,
                            lanFrom: 'defaultLan',
                          });
                        },
                      },
                    });
                  }}
                  size="small"
                  style={{ marginRight: '0.3rem' }}
                >
                  <LanguageIcon />
                </IconButton>
              }
            >
              <GridLayout
                justify="flex-start"
                elements={[
                  {
                    id: 'description',
                    element:
                      config.senctionDescription.type === 'textarea' ? (
                        <AppTextField
                          variant="outlined"
                          margin="normal"
                          size="medium"
                          error={'description' in errors}
                          helperText={
                            errors.description && errors.description.message
                          }
                          rules={{}}
                          required
                          fullWidth
                          id="description"
                          control={control}
                          placeholder="Enter description.."
                          autoComplete="description"
                          name="description"
                          multiline
                          rows={20}
                        />
                      ) : (
                        <RichTextEditor
                          initialState={initialRichText}
                          handleChange={(state) => {
                            descriptionEditorStateRef.current = state;
                            // console.log('State::', convertDraftToHtml(state));
                          }}
                        />
                      ),
                    size: 12,
                  },
                ]}
              />
            </AppCommon.Section>
          )}

          {config.sectionCollectionItems?.visibility?.includeItemsList && (
            <AppCommon.Section title="Course Content">
              <Box>
                <Box style={{ minHeight: '40vh' }}>
                  <TrickList
                    config={config}
                    ref={tricksRef}
                    register={register}
                    setValue={setValue}
                    control={control}
                    type={collectionType}
                    collectionId={routineId ?? null}
                  />
                </Box>

                {routineId && (
                  <Paper
                    variant="outlined"
                    style={{
                      paddingTop: '1rem',
                      paddingBottom: '1rem',
                      paddingLeft: '1rem',
                      paddingRight: '1rem',
                      background: colors['grey']['50'],
                    }}
                    className={classes.courseContentButtonsContainer}
                  >
                    <div className={classes.courseContentButtonsLayout}>
                      <Box>
                        <Button
                          // fullWidth
                          startIcon={<ListAltIcon />}
                          onClick={() => {
                            // open modal ...
                            openColLibrariesPerCollectionModal({
                              collectionId: routineId,
                              title: trickDetails.data?.title!,
                            });
                          }}
                          color="secondary"
                          variant="outlined"
                        >
                          {'Show Student Ratings'}
                        </Button>
                      </Box>

                      <Box>
                        {config.export && (
                          <ExportCollectionButton export={config.export} />
                        )}
                      </Box>
                    </div>
                  </Paper>
                )}
              </Box>
            </AppCommon.Section>
          )}

          <Box className={classes.submitContainer}>
            {/* {params.mode !== 'add' && (
              <SelectButton
                buttonProps={{
                  className: classes.submit,
                  color: 'primary',
                  variant: 'contained',
                  startIcon: <SystemUpdateAltOutlinedIcon />,
                }}
                items={[
                  {
                    action: async () => {
                      try {
                        await exportCollectionExercises(
                          routineId as string,
                          trickDetails.data?.title ?? 'collection'
                        );
                      } catch (error) {}
                    },
                    id: 'exportCollectionExercises',
                    children: (
                      <>
                        <ListItemIcon>
                          <AssignmentOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Exercises (.docx)" />
                      </>
                    ),
                  },
                  {
                    action: async () => {
                      try {
                        await exportCollectionText(
                          routineId as string,
                          trickDetails.data?.title ?? 'collection'
                        );
                      } catch (error) {}
                    },
                    id: 'exportCollectionText',
                    children: (
                      <>
                        <ListItemIcon>
                          <AssignmentOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Text (.docx)" />
                      </>
                    ),
                  },
                  {
                    action: async () => {
                      try {
                        await downloadCsvCollection(
                          routineId as string,
                          trickDetails.data?.title ?? 'collection'
                        );
                      } catch (error) {}
                    },
                    id: 'downloadCsv',
                    children: (
                      <>
                        <ListItemIcon>
                          <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Exercises (.csv)" />
                      </>
                    ),
                  },
                  // ...(routineId && roles.includes(Roles.Admin)
                  //   ? [
                  //       {
                  //         action: async () => {
                  //           const response =
                  //             await OpenAIService.fetchCollectionPrompt(
                  //               routineId
                  //             );

                  //           const mapData: Required<
                  //             OverrideValues<
                  //               ICollectionPromptData,
                  //               ItemTypeEnum | null
                  //             >
                  //           > = {
                  //             description: null,
                  //             titleTranslations: null,
                  //             itemsMultipleChoice: ItemTypeEnum.MultipleChoice,
                  //             itemsTrueFalse: ItemTypeEnum.TrueOrFalse,
                  //             itemsRearrangementQuestionAnswer:
                  //               ItemTypeEnum.RearrangementAnswer,
                  //             itemsQuestionAnswer: ItemTypeEnum.QuestionAnswer,
                  //             itemsRearrangement: ItemTypeEnum.Rearrangement,
                  //             itemsPhrasesByText: ItemTypeEnum.Phrase,
                  //             itemsPhrasesByTopic: ItemTypeEnum.Phrase,
                  //             itemsWordsByText: ItemTypeEnum.Word,
                  //             itemsWordsByTopic: ItemTypeEnum.Word,
                  //             itemsMultipleGap: ItemTypeEnum.FillTheGapMultiple,
                  //             descriptionByItems: null,
                  //             descriptionByTopic: null,
                  //           };

                  //           openPromptsModal({
                  //             prompts: Object.keys(response.data ?? {}).map(
                  //               (el) => ({
                  //                 title:
                  //                   formatCamelOrUnderscoreCaseToSentenceCase(
                  //                     el
                  //                   ),
                  //                 content: _.get(response.data, el),
                  //                 handleAddData: _.get(mapData, el)
                  //                   ? () => {
                  //                       openPromptInputModalModal({
                  //                         title: `${el} Prompt`,
                  //                         handleResult: async (data) => {
                  //                           try {
                  //                             await addItemsDataByPrompt({
                  //                               collectionId: routineId,
                  //                               promptResult: data,
                  //                               itemType: _.get(mapData, el),
                  //                             });
                  //                           } catch (error) {
                  //                             console.log(
                  //                               'openPromptInputModalModal:',
                  //                               error.message
                  //                             );
                  //                           }

                  //                           // refetch data
                  //                           dispatch(
                  //                             RoutineDetailsActions.fetchRoutineDetailsAction(
                  //                               routineId as string
                  //                             )
                  //                           );
                  //                           closePromptsModal();
                  //                         },
                  //                       });
                  //                     }
                  //                   : undefined,
                  //               })
                  //             ),
                  //           });
                  //         },
                  //         id: 'ViewPrompt',
                  //         children: (
                  //           <>
                  //             <ListItemIcon>
                  //               <DescriptionOutlinedIcon />
                  //             </ListItemIcon>
                  //             <ListItemText primary="View Prompt" />
                  //           </>
                  //         ),
                  //       },
                  //     ]
                  //   : []),
                ]}
              >
                <TranslatedText defaultText={'EXPORT'} />
              </SelectButton>
            )} */}

            {/* {params.mode !== 'add' &&
              routineId &&
              arraysHasCommonElements(roles, [Roles.Admin]) && (
                <Button
                  className={classes.submit}
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    const response = await OpenAIService.fetchCollectionPrompt(
                      routineId
                    );

                    const mapData: Required<
                      OverrideValues<ICollectionPromptData, ItemTypeEnum | null>
                    > = {
                      description: null,
                      titleTranslations: null,
                      itemsMultipleChoice: ItemTypeEnum.MultipleChoice,
                      itemsTrueFalse: ItemTypeEnum.TrueOrFalse,
                      itemsRearrangementQuestionAnswer:
                        ItemTypeEnum.RearrangementAnswer,
                      itemsQuestionAnswer: ItemTypeEnum.QuestionAnswer,
                      itemsRearrangement: ItemTypeEnum.Rearrangement,
                      itemsPhrasesByText: ItemTypeEnum.Phrase,
                      itemsPhrasesByTopic: ItemTypeEnum.Phrase,
                      itemsWordsByText: ItemTypeEnum.Word,
                      itemsWordsByTopic: ItemTypeEnum.Word,
                      itemsMultipleGap: ItemTypeEnum.FillTheGapMultiple,
                      descriptionByItems: null,
                      descriptionByTopic: null,
                    };

                    openPromptsModal({
                      prompts: Object.keys(response.data ?? {}).map((el) => ({
                        title: formatCamelOrUnderscoreCaseToSentenceCase(el),
                        content: _.get(response.data, el),
                        handleAddData: _.get(mapData, el)
                          ? () => {
                              openPromptInputModalModal({
                                title: `${el} Prompt`,
                                handleResult: async (data) => {
                                  try {
                                    await addItemsDataByPrompt({
                                      collectionId: routineId,
                                      promptResult: data,
                                      itemType: _.get(mapData, el),
                                    });
                                  } catch (error) {
                                    console.log(
                                      'openPromptInputModalModal:',
                                      error.message
                                    );
                                  }

                                  // refetch data
                                  dispatch(
                                    RoutineDetailsActions.fetchRoutineDetailsAction(
                                      routineId as string
                                    )
                                  );
                                  closePromptsModal();
                                },
                              });
                            }
                          : undefined,
                      })),
                    });
                  }}
                  startIcon={<SystemUpdateAltOutlinedIcon />}
                  // items={[
                  //   ...(routineId && roles.includes(Roles.Admin)
                  //     ? [
                  //         {
                  //           action: async () => {
                  //             const response =
                  //               await OpenAIService.fetchCollectionPrompt(
                  //                 routineId
                  //               );

                  //             const mapData: Required<
                  //               OverrideValues<
                  //                 ICollectionPromptData,
                  //                 ItemTypeEnum | null
                  //               >
                  //             > = {
                  //               description: null,
                  //               titleTranslations: null,
                  //               itemsMultipleChoice: ItemTypeEnum.MultipleChoice,
                  //               itemsTrueFalse: ItemTypeEnum.TrueOrFalse,
                  //               itemsRearrangementQuestionAnswer:
                  //                 ItemTypeEnum.RearrangementAnswer,
                  //               itemsQuestionAnswer: ItemTypeEnum.QuestionAnswer,
                  //               itemsRearrangement: ItemTypeEnum.Rearrangement,
                  //               itemsPhrasesByText: ItemTypeEnum.Phrase,
                  //               itemsPhrasesByTopic: ItemTypeEnum.Phrase,
                  //               itemsWordsByText: ItemTypeEnum.Word,
                  //               itemsWordsByTopic: ItemTypeEnum.Word,
                  //               descriptionByItems: null,
                  //               descriptionByTopic: null,
                  //             };

                  //             openPromptsModal({
                  //               prompts: Object.keys(response.data ?? {}).map(
                  //                 (el) => ({
                  //                   title:
                  //                     formatCamelOrUnderscoreCaseToSentenceCase(
                  //                       el
                  //                     ),
                  //                   content: _.get(response.data, el),
                  //                   handleAddData: _.get(mapData, el)
                  //                     ? () => {
                  //                         openPromptInputModalModal({
                  //                           title: `${el} Prompt`,
                  //                           handleResult: async (data) => {
                  //                             try {
                  //                               await addItemsDataByPrompt({
                  //                                 collectionId: routineId,
                  //                                 promptResult: data,
                  //                                 itemType: _.get(mapData, el),
                  //                               });
                  //                             } catch (error) {
                  //                               console.log(
                  //                                 'openPromptInputModalModal:',
                  //                                 error.message
                  //                               );
                  //                             }

                  //                             // refetch data
                  //                             dispatch(
                  //                               RoutineDetailsActions.fetchRoutineDetailsAction(
                  //                                 routineId as string
                  //                               )
                  //                             );
                  //                             closePromptsModal();
                  //                           },
                  //                         });
                  //                       }
                  //                     : undefined,
                  //                 })
                  //               ),
                  //             });
                  //           },
                  //           id: 'ViewPrompt',
                  //           children: (
                  //             <>
                  //               <ListItemIcon>
                  //                 <DescriptionOutlinedIcon />
                  //               </ListItemIcon>
                  //               <ListItemText primary="View Prompt" />
                  //             </>
                  //           ),
                  //         },
                  //       ]
                  //     : []),
                  // ]}
                >
                  <TranslatedText defaultText={'AI PROMPTS'} />
                </Button>
              )} */}

            <Button
              variant="contained"
              color="inherit"
              style={{
                background: colors.red['600'],
                color: colors.common.white,
              }}
              startIcon={<DeleteIcon />}
              className={classes.submit}
              onClick={() => {
                const removeItemImageId = 'removeItemImage';
                const removeCollectionImageId = 'removeCollectionImage';
                const removeContentId = 'removeContent';

                withVerification({
                  checkboxes: [
                    ...(getState().AuthReducer.roles?.includes(Roles.Admin)
                      ? [
                          {
                            id: removeCollectionImageId,
                            checked: false,
                            content: 'Remove collection image',
                          },
                        ]
                      : []),
                    {
                      id: removeContentId,
                      checked: true,
                      content:
                        'Remove collection content (phrases, words, exercises)',
                    },
                    ...(getState().AuthReducer.roles?.includes(Roles.Admin)
                      ? [
                          {
                            id: removeItemImageId,
                            checked: false,
                            content: 'Remove content images',
                          },
                        ]
                      : []),
                  ],
                  action1Event: (payload) => {
                    dispatch(
                      RoutineDetailsActions.removeRoutineAction({
                        payload: {
                          collectionId: routineId as string,
                          removeCollectionImage: payload?.checkboxes.find(
                            (el) => el.id === removeCollectionImageId
                          )?.checked,
                          removeContent: payload?.checkboxes.find(
                            (el) => el.id === removeContentId
                          )?.checked,
                          removeItemImage: payload?.checkboxes.find(
                            (el) => el.id === removeItemImageId
                          )?.checked,
                        },
                        onSuccess: () => {
                          history.push(NavigationConfig.routinesPage().path);
                        },
                      })
                    );
                  },
                  title: trickDetails.data?.title ?? `Remove ${collectionType}`,
                  description: (
                    <span>
                      <span
                        style={{ fontSize: '1.15em' }}
                      >{`Are you sure you want to remove the ${collectionType} `}</span>
                      <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                        {`${trickDetails.data?.title}?`}
                      </span>
                    </span>
                  ),
                });
              }}
            >
              <TranslatedText defaultText={'Remove'} />
            </Button>

            {params.mode !== 'view' && (
              <SubmitWrapper
                type="submit"
                variant="contained"
                color="secondary"
                startIcon={<EditOutlinedIcon />}
                className={classes.submit}
                onClick={() => {
                  onSubmit();
                }}
              >
                <TranslatedText
                  defaultText={
                    params.mode === 'add'
                      ? 'Add Collection'
                      : params.mode === 'edit'
                      ? 'Save'
                      : '-'
                  }
                />
              </SubmitWrapper>
            )}
          </Box>
        </AppCommon.FormContainer>
      </div>
    </>
  );
}

const QuizTypesWrapper = (props: Omit<IAdvancedSelectProps, ''>) => {
  const watchType = useWatch({
    control: props.control,
    name: 'type',
  }) as CollectionTypeEnum;
  return (
    <>
      {Object.values(CollectionTypeNeedsQuizType).includes(watchType) && (
        <Box display="flex">
          <div style={{ flex: 1, marginRight: '0.5rem' }}>
            <AppAdvancedselect {...props} />
          </div>
          <QuizTypesButton />
        </Box>
      )}
    </>
  );
};

const TagsSelectorWrapper = (props: Omit<IAdvancedSelectProps, 'options'>) => {
  const routineDetails = RoutineDetailsSelectors.useRoutineDetails();
  const tagsList = RoutineDetailsSelectors.useTagsList();

  const options = React.useMemo(() => {
    const newItems: {
      _id: string;
      description: string;
      subType?: TagSubTypes;
      name: string;
      level?: TagLevel[];
    }[] = [];

    tagsList.data?.items &&
      tagsList.data.items.forEach((item) => {
        item?.subType &&
          item.subType.forEach((subType) => {
            newItems.push({
              _id: item._id,
              description: item.description,
              subType: subType,
              name: item.name,
              level: item.level,
            });
          });
      });

    const tags = _.uniqBy(
      [
        ...(newItems?.map((el) => ({
          id: el.name,
          label: `${el.description} (${el.level?.toString()})`, // label: `${el.description} (${TagLevel?.[el.level!]})`,
          group: el.subType ?? 'OtherNamespace',
        })) || []),
        ...(routineDetails.data?.tags?.map((el) => ({
          label: `OtherNamespace_${el}`,
          id: el,
          group: 'OtherNamespace',
        })) ?? []),
      ],
      (el) => el.id
    );

    return groupOptions(tags);
  }, [tagsList, routineDetails]);

  const dispatch = useDispatch();

  return (
    <Box display="flex">
      <Button
        style={{ textTransform: 'none', marginTop: 15, marginBottom: 9 }}
        variant="outlined"
        onClick={() =>
          openTagDetailsModal({
            mode: 'add',
            onSuccess: () => {
              closeTagDetailsModal();
              dispatch(RoutineDetailsActions.fetchTagsListAction());
            },
            defaultValues: {
              description: '',
              name: '',
              namespaces: [],
              status: null,
              level: null,
              iconId: null,
              imageId: null,
              order: null,
              subType: null,
              iconImageId: null,
            },
            tagType: TagTypes.Collection,
          })
        }
      >
        <AddOutlinedIcon />
      </Button>
      <div style={{ flex: 1, marginLeft: '0.5rem' }}>
        <AppAdvancedselect {...props} isMulti options={options} />
      </div>
    </Box>
  );
};

const QuizTypes = Object.values(QuizTypeEnum).map((el) => ({
  id: el,
  label: el,
}));

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  const addTrickStatus = RoutineDetailsSelectors.useAddRoutineStatus();
  const editTrickStatus = RoutineDetailsSelectors.useEditRoutineStatus();

  return (
    <Button
      {...props}
      color="primary"
      disabled={
        addTrickStatus === StateStatus.Pending ||
        editTrickStatus === StateStatus.Pending
      }
    />
  );
};
