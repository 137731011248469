import React from 'react';
import Datatable from 'components/molecules/Datatable';
import { useDispatch } from 'react-redux';
import { viewFileUrl, downloadFile, openFileToNewTab } from 'services/api/file';
import Chip from '@material-ui/core/Chip';
import * as UploadefFilesSelectors from '../../store/selectors';
import UploadedFilesActions from '../../store/actions';
import SimpleListElement from 'components/atoms/SimpleListElement';
import TableActions, { OptionsButton } from '../TableAction';
import * as Configuration from '../../configuration';
import { IModalParams } from '../../types';
import PageviewIcon from '@material-ui/icons/Pageview';
import { formatDate } from 'utils/dates';
import ColumnName from '../ColumnName';
import { formatBytes } from 'utils/sizes';
import { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import { IState } from '../../store/reducer';
import { StateStatus } from 'redux/utils/common';
import { FileType, FileTypeActionsMapping } from 'constants/filesConst';
import UploadFileButton from '../UploadFileButton';
import { alpha, Box, colors, IconButton, Tooltip } from '@material-ui/core';
import EditImageButton from '../EditImageButton';
import { openUrlToNewTab } from 'utils/window';

const FilesList = (props: IModalParams) => {
  const result = UploadefFilesSelectors.useFilesList();
  const dispatch = useDispatch();
  // const query = Common.useQueryParams();
  const sortType = React.useRef<'asc' | 'desc'>(
    Configuration.DefaultPaging.orderType
  );
  const sortField = React.useRef<string>(Configuration.DefaultPaging.sortField);

  const paging = {
    page: result.data?.paging?.page || 0,
    pageSize: result.data?.paging?.pageSize || 0,
    total: result.data?.paging?.total || 0,
  };

  // props.type

  // console.log('RENDER WHY??????');

  return (
    <Datatable
      paging={paging}
      data={result.data?.items || []}
      sorting={{
        sortField: sortField.current,
        order: sortType.current,
      }}
      title={FileTypeActionsMapping?.[props?.type as FileType]?.name ?? 'Files'}
      disablePaper
      filtering={{
        searchFields: true,
        globalSearchPlaceholder: `Search for ${
          FileTypeActionsMapping?.[props?.type as FileType]?.name ?? 'files'
        }..`,
        globalSearchDefaultValue: props.initialSearch,
      }}
      renderItemInSmallDevices={(data, index) => {
        return (
          <SimpleListElement
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ColumnName name={data.name ?? null} fileId={data._id} />
              </div>
            }
            actionLeftButton={
              <div style={{ position: 'relative' }}>
                <UploadFileButton fileId={data._id} />
                <img
                  style={{ marginRight: '1rem', cursor: 'pointer' }}
                  height={80}
                  width={80}
                  onClick={() => {
                    openFileToNewTab(data.path);
                  }}
                  src={
                    data.fileType === FileType.Image
                      ? viewFileUrl(data.path)
                      : require('assets/images/icons/uploaded-file.png')
                  }
                />
              </div>
            }
            actionRightButton={<TableActions small {...props} params={data} />}
            subTitle={
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  marginRight: '0.5rem',
                  marginTop: '0.8rem',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    marginRight: '0.5rem',
                    marginTop: '0.8rem',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Chip
                      size="small"
                      variant="outlined"
                      style={{ marginRight: 7 }}
                      label={<div>{formatBytes(data.size ?? 0)}</div>}
                    />
                    <EditImageButton
                      _id={data._id}
                      path={data.path}
                      name={data.name ?? ''}
                      fileName={data.fileName}
                    />
                  </div>
                  <div>{formatDate(data.createdAt)}</div>
                </div>
              </div>
            }
          />
        );
      }}
      minHeight={'100vh'}
      paperProps={{
        style: {
          borderRadius: 2,
          //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
          overflow: 'hidden',
        },
        // square: false,
        variant: 'outlined',
      }}
      tableCellProps={() => ({
        style: {
          //   paddingTop: 0,
          //   paddingBottom: 0,
          borderLeft: 'none',
          borderRight: 'none',
        },
      })}
      tableCellMainRowProps={() => ({
        style: {
          paddingTop: '1rem',
          paddingBottom: '1rem',
        },
      })}
      loading={false}
      columns={Configuration.TableColumns(props)}
      handleDataChange={(
        selectedPage,
        pageSize,
        previousOrder,
        _sortField,
        newOrder,
        filteringData
      ) => {
        const currentState = (getState() as any)[
          ReducerKeys.UPLOADED_FILES_REDUCER
        ] as IState;
        if (currentState.filesList.status !== StateStatus.Pending) {
          // 'asc' | 'desc'
          sortType.current = newOrder;
          sortField.current = _sortField;
          dispatch(
            UploadedFilesActions.fetchFilesAction({
              payload: {
                page: selectedPage,
                pageSize,
                orderType: newOrder,
                globalSearch: filteringData.__globalValue,
                attachmentType: filteringData.attachmentType,
                fileType: filteringData.fileType,
                sortField: _sortField,
              },
              onSuccess: () => {},
            })
          );
        }
      }}
    />
  );
};

export default React.memo(FilesList);
